import logo from './logo.svg';
import Form from './Form';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="Header">
        <h2>SFMTA Lost and Found</h2>
        <h4>Please report your lost item here.</h4>
      </div>
      <Form></Form>
    </div>
  );
}

export default App;
