import React from 'react';

class DropdownRow extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            itemTypes : [],
            itemDetails : [],
            selectedType : '--Select Item Type--',
            selectedDetail : '--Select Item Detail--',
        };
        this.changeType = this.changeType.bind(this);
        this.changeDetail = this.changeDetail.bind(this);
    }

    componentDidMount() {
        this.setState({
            itemTypes : [
                {type: 'Backpack/Bag/Briefcase/Luggage',
                details : ['Backpack','Fannypack','Briefcase','Messenger Bag','Satchel','Cosmetic Bag/Case','Duffle or Gym Bag','Luggage','Paper or Plastic Bag','Tote Bag','Other - Specify Details Below']},
                {type: 'Bicycle/Roller Blades/Skates/Skateboard',
                details: ["Children's Bicycle","Men's Bicycle","Women's Bicycle",'Roller Blades or Skates','Skateboard','Scooter','Other - Specify Details Below']},
                {type: 'Book(s)',
                details: ['Public Library Book(s)','School/Text Book(s)','Hard Cover','Paperback','Other - Specify Details Below']},
                {type: 'Camera and Accessories',
                details: ['Camcorder','Digital Camera','Film Camera','Single-Use Camera','Tripod/Accessories','Other - Specify Details Below']},
                {type: 'Case(s)/Container(s)/Lunch Box',
                details: ['Case(s)/Container(s)/Box(es)','Lunch Box/Bag','Other - Specify Details Below']},
                {type: 'Cell Phone/Electronics/Media',
                details: ['CD/DVD','Cell Phone','Laptop','Media PLayer','Media Storage Device','Tablet/eReader','Other - Specify Details Below']},
                {type: 'Clothing',
                details: ['Cap/Hat','Helmet','Coat','Dress','Skirt','Gloves/Mittens','Pants','Scarf/Shawl','Shirt/Blouse','Shoes/Footware','Suit','Sweater/Sweatshirt','Swim Wear','Undergarments','Other - Specify Details Below']},
                {type: 'Envelope/Folder/Notebook',
                details:['Binder/Folder','Art Portfolio','Envelope(s)','Notebook(s)','Planner/Organizer','Other - Specify Details Below']},
                {type:'Eyeglasses',
                details:['Prescription/Reading Glasses','Sunglasses','Other - Specify Details Below']},
                {type:'Identification/Sensitive Documents/Transit Pass',
                details:["Driver's License",'Employee ID or Badge','Identifcation Card','Passport','Sensitive Documents','Social Security Card','Transit Pass','Other - Specify Details Below']},
                {type: 'Jewelry/Watch',
                details:['Bracelet',"Brooch/Pin","Earring(s)",'Necklace/Pendant','Ring(s)','Wirst or Pocket Watch','Other - Specify Details Below']},
                {type:'Key(s)', details:['Key(s)','Other - Specify Details Below']},
                {type:'Medical Equipment/Medication',
                details: ['Cane','Crutches','Dentures/Retainer/Other Dental','Diabetes Kit','Hearing Aid','Medication - specify type below','Other - Specify Details Below']},
                {type: 'Musical Instrument',
                details: ['Specify Details Below']},
                {type: 'Sports Equipment', details: ['Specify Details Below']},
                {type: 'Umbrella', details:['Compact','Full Size','Other - Specify Details Below']},
                {type:'Wallet/Purse/Card Holder/Checkbook',
                details:['Card Holder','Checkbook/Checks','Coin Pouch','Purse/Handbag','Wallet','Other - Specify Details Below']},
                {type: 'All Other Items(not listed above)', details:['Specify Details Below']}
            ]

        });
    }

    changeType(event){
        this.setState({selectedType: event.target.value});
        this.setState({itemDetails : this.state.itemTypes.find(entry => entry.type === event.target.value).details});
    }

    changeDetail(event){
        this.setState({selectedDetail: event.target.value});
    }

    render(){
        return(
            <div>
                <tr>
                <td>
                    <label>Item Type</label>
                </td>
                <td>
                <select placeholder="Item Type" value={this.state.selectedType} onChange={this.changeType}>
                        <option>--Choose Item Type--</option>
                        {this.state.itemTypes.map((e,key)=> {
                            return <option key={key}>{e.type}</option>
                        })}
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Item Detail</label>
                </td>
                <td>
                    <select placeholder="Item Detail" value={this.state.selectedDetail} onChange={this.changeDetail}>
                        <option>--Choose Item Detail--</option>
                        {this.state.itemDetails.map((e,key) => {
                            return <option key={key}>{e}</option>
                        })}
                    </select>
                </td>
            </tr>
                 

            </div>
               
                    
               
                    
                    
                
        )
    }
}

export default DropdownRow;