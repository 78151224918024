import React from 'react';
import Dropdown from './Dropdown';
import DropdownRow from './DropdownRow';
import './Form.css';

class Form extends React.Component{

    render(){
        return (
            <div className='formWrapper'>
    
                <form action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
    
                    <input type="hidden" name="orgid" value="00D5f000002ZhCV" />
                    <input type="hidden" name="retURL" value="http://rob-egan.com" />


                    <div className="formBox">
                        <h5>Enter Contact Information</h5>
                        <table className="formTable">
                        <tr>
                            <td>
                                <label for="name">Name:</label>
                            </td>
                            <td>
                                <input  id="name" maxlength="80" name="name" size="20" type="text" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="email">Email Address:</label>
                            </td>
                            <td>
                                <input  id="email" maxlength="80" name="email" size="20" type="text" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="phone">Phone Number:</label>
                            </td>
                            <td>
                                <input  id="phone" maxlength="40" name="phone" size="20" type="text" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="subject">Subject:</label>
                            </td>
                            <td>
                                <input  id="subject" maxlength="80" name="subject" size="20" type="text" />
                            </td>
                        </tr>
                        </table>
                    </div>
                    
                    <div className="formBox">
                        <h5>Enter Travel Information</h5>
                        <label for="Route">Route:</label>
                            
                        <select  id="00N5f000006oY0N" name="00N5f000006oY0N" title="Route">
                        <option value="">--None--</option><option value="Not Applicable/Unknown">Not Applicable/Unknown</option>
                        <option value="E - Embarcadero">E - Embarcadero</option>
                        <option value="F - Market and Wharves">F - Market and Wharves</option>
                        <option value="J - Church">J - Church</option>
                        <option value="K - Ingleside">K - Ingleside</option>
                        <option value="L - Taraval">L - Taraval</option>
                        <option value="L - Owl - Taraval">L - Owl - Taraval</option>
                        <option value="M - Ocean View">M - Ocean View</option>
                        <option value="N - Judah">N - Judah</option>
                        <option value="NX - Judah Express">NX - Judah Express</option>
                        <option value="N - Owl - Judah">N - Owl - Judah</option>
                        <option value="S - Shuttle Train">S - Shuttle Train</option>
                        <option value="T - Third">T - Third</option>
                        <option value="TM - Third/Ocean View">TM - Third/Ocean View</option>
                        <option value="California Cable Car">California Cable Car</option>
                        <option value="Powell/Hyde Cable Car">Powell/Hyde Cable Car</option>
                        <option value="Powell/Mason Cable Car">Powell/Mason Cable Car</option>
                        <option value="1 - California">1 - California</option>
                        <option value="1AX - California &#39;A&#39; Express">1AX - California &#39;A&#39; Express</option>
                        <option value="1BX - California &#39;B&#39; Express">1BX - California &#39;B&#39; Express</option>
                        <option value="2 - Clement">2 - Clement</option>
                        <option value="3 - Jackson">3 - Jackson</option>
                        <option value="5 - Fulton">5 - Fulton</option>
                        <option value="5R - Fulton Rapid">5R - Fulton Rapid</option>
                        <option value="6 - Haight/Parnassus">6 - Haight/Parnassus</option>
                        <option value="7 - Haight/Noriega">7 - Haight/Noriega</option>
                        <option value="7X - Noriega Express">7X - Noriega Express</option>
                        <option value="8 - Bayshore">8 - Bayshore</option>
                        <option value="8AX - Bayshore &#39;A&#39; Express">8AX - Bayshore &#39;A&#39; Express</option>
                        <option value="8BX - Bayshore &#39;B&#39; Express">8BX - Bayshore &#39;B&#39; Express</option>
                        <option value="9 - San Bruno">9 - San Bruno</option>
                        <option value="9R - San Bruno Rapid">9R - San Bruno Rapid</option>
                        <option value="10 - Townsend">10 - Townsend</option>
                        <option value="12 - Folsom/Pacific">12 - Folsom/Pacific</option>
                        <option value="14 - Mission">14 - Mission</option>
                        <option value="14R - Mission Rapid">14R - Mission Rapid</option>
                        <option value="14X - Mission Express">14X - Mission Express</option>
                        <option value="15 - Bayview-Hunters Point Express">15 - Bayview-Hunters Point Express</option>
                        <option value="18 - 46th Avenue">18 - 46th Avenue</option>
                        <option value="19 - Polk">19 - Polk</option>
                        <option value="21 - Hayes">21 - Hayes</option>
                        <option value="22 - Fillmore">22 - Fillmore</option>
                        <option value="23 - Monterey">23 - Monterey</option>
                        <option value="24 - Divisadero">24 - Divisadero</option>
                        <option value="25 - Treasure Island">25 - Treasure Island</option>
                        <option value="27 - Bryant">27 - Bryant</option>
                        <option value="28 - 19th Avenue">28 - 19th Avenue</option>
                        <option value="28R - 19th Avenue Rapid">28R - 19th Avenue Rapid</option>
                        <option value="29 - Sunset">29 - Sunset</option>
                        <option value="30 - Stockton">30 - Stockton</option>
                        <option value="30X - Marina Express">30X - Marina Express</option>
                        <option value="31 - Balboa">31 - Balboa</option>
                        <option value="31AX - Balboa &#39;A&#39; Express">31AX - Balboa &#39;A&#39; Express</option>
                        <option value="31BX - Balboa &#39;B&#39; Express">31BX - Balboa &#39;B&#39; Express</option>
                        <option value="33 - Ashbury/18th">33 - Ashbury/18th</option>
                        <option value="35 - Eureka">35 - Eureka</option>
                        <option value="36 - Teresita">36 - Teresita</option>
                        <option value="37 - Corbett">37 - Corbett</option>
                        <option value="38 - Geary">38 - Geary</option>
                        <option value="38R - Geary Rapid">38R - Geary Rapid</option>
                        <option value="38AX - Geary &#39;A&#39; Express">38AX - Geary &#39;A&#39; Express</option>
                        <option value="38BX - Geary &#39;B&#39; Express">38BX - Geary &#39;B&#39; Express</option>
                        <option value="39 - Coit">39 - Coit</option>
                        <option value="41 - Union">41 - Union</option>
                        <option value="43 - Masonic">43 - Masonic</option>
                        <option value="44 - O&#39;shaughnessy">44 - O&#39;shaughnessy</option>
                        <option value="45 - Union/Stockton">45 - Union/Stockton</option>
                        <option value="47 - Van Ness">47 - Van Ness</option>
                        <option value="48 - Quintara/24th Street">48 - Quintara/24th Street</option>
                        <option value="49 - Van Ness/Mission">49 - Van Ness/Mission</option>
                        <option value="52 - Excelsior">52 - Excelsior</option>
                        <option value="54 - Felton">54 - Felton</option>
                        <option value="56 - Rutland">56 - Rutland</option>
                        <option value="57 - Park Merced">57 - Park Merced</option>
                        <option value="58 - Lake Merced">58 - Lake Merced</option>
                        <option value="66 - Quintara">66 - Quintara</option>
                        <option value="67 - Bernal Heights">67 - Bernal Heights</option>
                        <option value="76X - Marin Headlands Express">76X - Marin Headlands Express</option>
                        <option value="78X - 16th Street Arena Express">78X - 16th Street Arena Express</option>
                        <option value="79X - Van Ness Arena Express">79X - Van Ness Arena Express</option>
                        <option value="81X - Caltrain Express">81X - Caltrain Express</option>
                        <option value="82X - Levi Plaza Express">82X - Levi Plaza Express</option>
                        <option value="83X - Mid-Market Express">83X - Mid-Market Express</option>
                        <option value="88 - BART Shuttle">88 - BART Shuttle</option>
                        <option value="90 - San Bruno Owl">90 - San Bruno Owl</option>
                        <option value="91 - Third St/19th Ave Owl">91 - Third St/19th Ave Owl</option>
                        <option value="714 - BART Early Bird">714 - BART Early Bird</option>
                    </select><br/>
                    <label>Vehicle Number:</label>
                    <input  id="00N5f000006qOmX" name="00N5f000006qOmX" size="20" type="text" /><br/>
                    <label>Direction:</label>
                    <select  id="00N5f000006qOmr" name="00N5f000006qOmr" title="Direction"><option value="">--None--</option><option value="Inbound">Inbound</option>
                        <option value="Outbound">Outbound</option>
                    </select><br/>
                    <label>Date Lost:</label>
                    <span class="dateInput dateOnlyInput"><input  id="00N5f000006qOmN" name="00N5f000006qOmN" size="12" type="text" /></span><br/>
                    <lable>Time Lost:</lable>
                    <span class="timeContainer"><input  id="00N5f000006qOn6" name="00N5f000006qOn6" placeholder="HH:MM" size="12" type="text" /></span><br/>
                    </div>

                    <div className="formBox">
                        <h5>Enter Item Information</h5>
                        <Dropdown></Dropdown>
                        <label for="brand">Brand:</label><input  id="00N5f000006oY1Q" maxlength="50" name="00N5f000006oY1Q" size="20" type="text" /><br/>
    
                    
                        <label>Color:</label>
                        <select  id="00N5f000006oY1k" name="00N5f000006oY1k" title="Color">
                        <option value="">--None--</option>
                        <option value="Aqua/Blue-Green">Aqua/Blue-Green</option>
                        <option value="Beige/Tan">Beige/Tan</option>
                        <option value="Black">Black</option>
                        <option value="Blue">Blue</option>
                        <option value="Brown">Brown</option>
                        <option value="Gold">Gold</option>
                        <option value="Gray">Gray</option>
                        <option value="Green">Green</option>
                        <option value="Orange">Orange</option>
                        <option value="Pink">Pink</option>
                        <option value="Purple/Violet">Purple/Violet</option>
                        <option value="Red">Red</option>
                        <option value="Silver">Silver</option>
                        <option value="Turquoise">Turquoise</option>
                        <option value="White">White</option>
                        <option value="Yellow">Yellow</option>
                        <option value="Transparent/Translucent">Transparent/Translucent</option>
                        <option value="Other - enter in Details below">Other - enter in Details below</option>
                    </select><br/>

                    <label for="description">Description:</label><textarea name="description"></textarea><br/>
                    </div>
                        
                    <div className="formBox">
                        <h5>Submit When Ready</h5>
                        <input type="submit" name="submit"/>
                        <input type="reset" name="reset"/>
                    </div>
                    
                    
    
                </form>
    
    
            </div>
        );
    }

}
export default Form;